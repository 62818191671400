import { Container } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation, Autoplay } from "swiper";

import { useNavigate } from "react-router-dom";

import "./Trending.css";

import {
  useAddOrRemoveFavouriteProductMutation,
  useGetAllOffersQuery,
  useGetFavouritesProductsQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { Button, Spin } from "antd";
import { language } from "../../language/Language";
import ServerError from "../../components/utils/ServerError";

const Trending = () => {
  const route = useNavigate();

  const {
    data: allOfferssData,
    isError: isAllOffersError,
    isLoading: isAllOffersLoading,
    error: allOffersError,
  } = useGetAllOffersQuery();

  const allOffers = allOfferssData?.data ?? null;

  const handlepropa = (e) => {
    e.stopPropagation();
  };

  const [
    addOrDeleteProduct,
    {
      isLoading: addOrDelIsLoading,
      isError: addOrDelIsError,
      error: addOrDelError,
    },
  ] = useAddOrRemoveFavouriteProductMutation();
  const {
    data: favItemsData,
    isError: isFavError,
    isLoading: isFavLoading,
    error: favError,
  } = useGetFavouritesProductsQuery();

  const favItems = favItemsData?.data ?? null;

  const handlefav = (ele) => {
    addOrDeleteProduct({ offer_id: ele.id });
  };

  if (isAllOffersError) {
    return <ServerError />;
  }
  return (
    <section
      className="Trending section_padding"
      data-aos={"fade-up"}
      style={{ position: "relative" }}
    >
      {isAllOffersLoading && <Loading />}

      {allOffers?.length > 0 && (
        <h2 className="main_heading">
          {language === "ar" ? "أفضل العروض" : "Best Offers"}
        </h2>
      )}
      <Container>
        <Swiper
          slidesPerView={2}
          spaceBetween={10}
          slidesPerGroup={1}
          initialSlide={2}
          loop={true}
          autoplay={{
            delay: 2000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          loopFillGroupWithBlank={true}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
          breakpoints={{
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {allOffers?.map((offer, i) => (
            <SwiperSlide key={i + 1}>
              <figure onClick={() => route(`/offer/${offer.id}`)}>
                <div className="trend_image_hold">
                  <img src={offer.icon} alt="trending" />
                </div>
                <figcaption>
                  <p className="image_desc">{offer.name}</p>
                  <div className="price">
                    <span className="currentPrice">
                      {language === "ar" ? "ر.س" : "SAR"} {offer.price}
                    </span>
                  </div>
                  <ul className="trend_image_option" onClick={handlepropa}>
                    <li
                      onClick={() => handlefav(offer)}
                      className={favItems
                        ?.map((el) =>
                          el.item_id === offer.id && el.is_offer
                            ? "favicon"
                            : ""
                        )
                        .join("")}
                    >
                      {addOrDelIsLoading && <Spin />}
                    </li>
                  </ul>
                </figcaption>
              </figure>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
};

export default Trending;
