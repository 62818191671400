import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useEffect, useState } from "react";
import mixitup from "mixitup";
import {
  faCartArrowDown,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import "./Products.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemtochart,
  addItemtofav,
  deleteFavItem,
} from "../../redux/action";
import AOS from "aos";
import {
  useAddOrRemoveFavouriteProductMutation,
  useGetAllBrandsQuery,
  useGetAllCategoriesQuery,
  useGetAllProductsQuery,
  useGetFavouritesProductsQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { language } from "../../language/Language";
import { Drawer, Spin } from "antd";
import { Button } from "antd";
import ServerError from "../../components/utils/ServerError";
// AOS.init({
//   once: false,
// });

const Products = () => {
  const [filterdProducts, setFilterdProducts] = useState(null);
  const [openCategorySideBar, setOpenCategorySideBar] = useState(false);
  const [openBrandSideBar, setOpenBrandSideBar] = useState(false);

  const dispatch = useDispatch();
  const catsref = useRef();
  const brandsref = useRef();
  const route = useNavigate();

  const {
    data: allProductsData,
    isError: isProdsError,
    isLoading: isProdsLoading,
    error: prodsError,
  } = useGetAllProductsQuery();
  const {
    data: allCategoriesData,
    isError: isCatError,
    isLoading: isCatLoading,
    error: catError,
  } = useGetAllCategoriesQuery();
  const {
    data: allBrandsData,
    isError: isBrandError,
    isLoading: isCbrandoading,
    error: brandError,
  } = useGetAllBrandsQuery();

  const allProducts = allProductsData?.data?.data ?? null;
  const allCategories = allCategoriesData?.data ?? null;
  const allBrands = allBrandsData?.data ?? null;

  const catParam = window.location.hash.split("#").pop();

  useEffect(() => {
    if (!catParam) {
      setFilterdProducts(() => {
        if (allProducts) {
          const allFProducts = [...allProducts]?.sort(
            (a, b) => a.category.id - b.category.id
          );
          return allFProducts;
        }
      });
    } else {
      setFilterdProducts(
        allProducts?.filter((product) => product.category.id == catParam)
      );
    }
  }, [allProducts]);

  const [
    addOrDeleteProductInFavourite,
    {
      isLoading: addOrDelIsLoading,
      isError: addOrDelIsError,
      error: addOrDelError,
    },
  ] = useAddOrRemoveFavouriteProductMutation();
  const {
    data: favItemsData,
    isError: isFavError,
    isLoading: isFavLoading,
    error: favError,
  } = useGetFavouritesProductsQuery();

  const favItems = favItemsData?.data ?? null;

  const handlepropa = (e) => {
    e.stopPropagation();
  };
  const handlefav = (ele) => {
    addOrDeleteProductInFavourite({ item_id: ele.id });
  };

  const handleCatNameClick = (ele, catID) => {
    let lis = [...catsref.current.children];

    lis.forEach((li) => {
      li.classList?.remove("active");
    });

    ele.target.classList?.add("active");

    setFilterdProducts(() => {
      if (catID == 0) {
        const allFProducts = [...allProducts].sort(
          (a, b) => a.category.id - b.category.id
        );
        return allFProducts;
      } else {
        return allProducts?.filter((product) => product.category.id == catID);
      }
    });
    setOpenCategorySideBar(false);
  };
  const handleBrandNameClick = (ele, brandID) => {
    let lis = [...brandsref.current.children];

    lis.forEach((li) => {
      li.classList?.remove("active");
    });

    ele.target.classList?.add("active");

    setFilterdProducts(() => {
      if (brandID == 0) {
        const allFProducts = [...allProducts].sort((a, b) => {
          const aBrandId = a.brand ? a.brand.id : 0;
          const bBrandId = b.brand ? b.brand.id : 0;
          return aBrandId - bBrandId;
        });
        return allFProducts;
      } else {
        return allProducts?.filter((product) => {
          if (product.brand && product.brand.id) {
            return product.brand.id == brandID;
          }
          return false;
        });
      }
    });
    setOpenBrandSideBar(false);
  };

  if (isProdsError) {
    return <ServerError />;
  }

  return (
    <section className="products-main-page">
      {isProdsLoading && <Loading />}

      <Drawer
        title={language === "ar" ? "الاصناف" : "Categories"}
        onClose={() => setOpenCategorySideBar(false)}
        open={openCategorySideBar}
        placement={language === "ar" ? "right" : "left"}
      >
        <ul className="products_categories-drawer" ref={catsref}>
          <li
            className={!catParam ? "active" : null}
            data-filter="all"
            onClick={(e) => handleCatNameClick(e, 0)}
          >
            {language === "ar" ? "الكل" : "All"}
          </li>
          {allCategories?.map((category) => (
            <li
              data-filter={`.cat${category.id}`}
              key={category.id}
              onClick={(e) => handleCatNameClick(e, category.id)}
              className={catParam == category.id ? "active" : null}
            >
              {category.name}
            </li>
          ))}
        </ul>
      </Drawer>
      <Drawer
        title={language === "ar" ? "الماركات" : "Brands"}
        onClose={() => setOpenBrandSideBar(false)}
        open={openBrandSideBar}
        placement={language === "ar" ? "right" : "left"}
      >
        <ul className="products_categories-drawer" ref={brandsref}>
          <li
            className={!catParam ? "active" : null}
            data-filter="all"
            onClick={(e) => handleBrandNameClick(e, 0)}
          >
            {language === "ar" ? "الكل" : "All"}
          </li>
          {allBrands?.map((brand) => (
            <li
              data-filter={`.cat${brand.id}`}
              key={brand.id}
              onClick={(e) => handleBrandNameClick(e, brand.id)}
              className={catParam == brand.id ? "active" : null}
            >
              {brand.name}
            </li>
          ))}
        </ul>
      </Drawer>

      <h2 className="main_heading">
        {language === "ar" ? "منتجاتنا" : "Our Products"}
      </h2>
      <Container className="categories_drawer-btn-container">
        <Button
          className="categories_drawer-btn"
          type="primary"
          onClick={() => setOpenCategorySideBar(true)}
        >
          {language === "ar" ? "فلتر حسب اسم الصنف" : "Filter By Category Name"}
        </Button>
        <Button
          className="categories_drawer-btn cat-btn"
          type="primary"
          onClick={() => setOpenBrandSideBar(true)}
        >
          {language === "ar" ? "فلتر حسب اسم الماركة" : "Filter By Brand Name"}
        </Button>
      </Container>
      <Container className="my-5">
        <Row>
          {filterdProducts?.map((prod) => (
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={prod.id}
              className={`mb-4 cat${prod.category.id} mix`}
            >
              <div
                className="product"
                onClick={() => route(`/Products/${prod.id}`)}
                // data-aos="fade-up"
              >
                <div className="image_hold">
                  <img src={prod.icon} alt="product" className="mw-100" />
                </div>
                <div className="product_info">
                  <p className="image_desc">{prod.name}</p>
                  <div className="price">
                    <span
                      className={
                        prod.discount !== 0 ? "oldprice" : "currentPrice"
                      }
                    >
                      {prod.price}
                      <br />
                      {language === "ar" ? "ر.س" : "SAR"}
                    </span>
                    {prod.discount !== 0 && (
                      <>
                        <span className="currentPrice">
                          {prod.discount}
                          <br />
                          {/* {prod.is_dollar == 1 ? "$" : "ر.س"} */}
                          {language === "ar" ? "ر.س" : "SAR"}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="foote mt-3">
                    <ul className="product_option" onClick={handlepropa}>
                      <li
                        onClick={() => handlefav(prod)}
                        className={favItems
                          ?.map((el) =>
                            el.item_id === prod.id && !el.is_offer
                              ? "favicon"
                              : ""
                          )
                          .join("")}
                      >
                        {addOrDelIsLoading && <Spin />}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Products;
