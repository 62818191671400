import "./MyOrder.css";
import { Steps } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLayoutEffect } from "react";
import { useGetAllUserOrdersQuery } from "../../redux/Service/api";
import { faBookOpenReader } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/utils/Loading";
import ServerError from "../../components/utils/ServerError";
import { language } from "../../language/Language";
const description = "This is a description";

function MyOrder() {
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((st) => st.auth);
  useLayoutEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, []);

  const {
    data: allOrdersData,
    isLoading,
    isError,
  } = useGetAllUserOrdersQuery();
  const allOrders = allOrdersData?.data?.data ?? null;

  return (
    <>
      <div
        className="orders position-relative"
        style={{ minHeight: "100vh", direction: "ltr" }}
      >
        <h1>
          طلباتك المكتملة
          {/* {user ? "Your Completed Orders" : "Sign In First"} */}
        </h1>
        {isLoading && <Loading />}
        {isError && <ServerError />}
        <div className="gap-5 d-flex flex-column">
          {allOrders?.map((order) => (
            <div key={order.id} className="orders__order">
              <div className="my-5 d-flex justify-content-center align-items-center">
                <Steps
                  className="col-12 col-md-10"
                  current={
                    order.status == "pending"
                      ? 0
                      : order.status == "underPrepare"
                      ? 1
                      : order.status == "onTheWay"
                      ? 2
                      : order.status == "delivery"
                      ? 4
                      : 0
                  }
                  status="process"
                  items={[
                    {
                      title: "قيد الانتظار",
                    },
                    {
                      title: "قيد المعالجة",
                    },
                    {
                      title: "في الطريق",
                    },
                    {
                      title: "تم التوصيل",
                    },
                  ]}
                />
              </div>
              <div className="d-flex g-5 fw-bold flex-wrap justify-content-between my-0 px-3">
                <p className="my-0">
                  تم الإنشاء في:{" "}
                  <span className="fw-medium" style={{ fontSize: "0.9rem" }}>
                    {order.created_at}
                  </span>
                </p>
                {order.delivery_price > 0 && (
                  <>
                    <p className="my-0">
                      السعر :{" "}
                      <span
                        className="fw-medium"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {order.price} {language === "ar" ? "ر.س" : "SAR"}
                      </span>
                    </p>
                    <p className="my-0">
                      تكلفة التسليم:{" "}
                      <span
                        className="fw-medium"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {order.delivery_price}{" "}
                        {language === "ar" ? "ر.س" : "SAR"}
                      </span>
                    </p>
                  </>
                )}
                <p className="my-0">
                  التكلفة الإجمالية:{" "}
                  <span className="fw-medium" style={{ fontSize: "0.9rem" }}>
                    {order.total} {language === "ar" ? "ر.س" : "SAR"}
                  </span>
                </p>
              </div>
              {order.items?.map((item) => (
                <div className="order" key={item.id}>
                  <h3>{item.name ? "Product" : "Offer"}</h3>

                  <div className="checkoutProduct">
                    <img
                      className="checkoutProduct__image"
                      style={{ height: "10rem" }}
                      src={item.icon}
                      alt=""
                    />
                    <div className="checkoutProduct__info">
                      <p className="checkoutProduct__title">
                        {item.name ? item.name : "offer"}
                      </p>
                      <p className="checkoutProduct__title fw-light">
                        عدد : <span className="fw-bold">{item.count}</span>
                      </p>
                      <p className="checkoutProduct__price">
                        السعر:
                        <strong className="mx-1">{item.price}</strong>
                        <small>ر.س</small>
                      </p>

                      {/* <button>Remove from Basket</button> */}
                    </div>
                  </div>
                  <h3 className="order__total">
                    تكلفة الطلب:{" "}
                    <span className="fw-bold">{item.price * item.count}</span>
                  </h3>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default MyOrder;
