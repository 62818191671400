import BannerImage from "../../components/BannerImage/BannerImage";
import Identification from "../../components/Identification/Identification";
import {
  Category,
  Landing,
  OurAdvantages,
  Testimonials,
  Trending,
} from "../../Sections";
import BestSellingProducts from "../../Sections/BestSellingProducts/BestSellingProducts";
import FeaturedProducts from "../../Sections/FeaturedProducts/FeaturedProducts";
import Articles from "../Articles/Articles";
import ProductsSlider from "../../Sections/ProductsSlider/ProductsSlider";
import CategoriesWithProducts from "../../Sections/CategoriesWithProducts";

const Home = () => {
  return (
    <>
      <Landing />
      <BestSellingProducts />
      <CategoriesWithProducts />
      <FeaturedProducts />
      {/* Category => Brands */}
      <Category />
      {/* Trending => offers */}
      <Trending />
      {/* <Testimonials /> */}
      <Identification />
      <Articles inHome={true} />
      <OurAdvantages />
    </>
  );
};

export default Home;
