import { Col, Container, Row } from "react-bootstrap";
import "./OurAdvantages.css";
import { images } from "../../constants";
import { language } from "../../language/Language";

const OurAdvantages = () => {
  return (
    <section
      className="OurAdvantages section_padding my-5"
      data-aos={"fade-up"}
    >
      {/* <h2 className='main_heading'>{language === "ar" ? "خدماتنا" : "Our Services"}</h2> */}
      <Container>
        <Row className="justify-content-center">
          <Col
            xs={12}
            sm={6}
            lg={3}
            className="flex_center flex-column mb-4 mb-lg-0 text-center col"
          >
            <img src={images.services1} alt="OurAdvantage" />
            <p className="adv_title">
              {language === "ar" ? "توصيل سريع " : "Free Delivery"}
            </p>
            {/* <p className="adv_text">
              {language === "ar"
                ? "التوصيل مجاني لجميع الطلبات"
                : "Free delivery on all orders"}
            </p> */}
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={3}
            className="flex_center flex-column mb-4 mb-lg-0 text-center col"
          >
            <img src={images.services2} alt="OurAdvantage" />
            <p className="adv_title">
              {language === "ar" ? "دفع أمن" : "Secure Payment"}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OurAdvantages;
