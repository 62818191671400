import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Products.css";
import {
  useAddOrRemoveFavouriteProductMutation,
  useGetFavouritesProductsQuery,
} from "../../redux/Service/api";
import { language } from "../../language/Language";
import { Spin } from "antd";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";

import { FreeMode, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const ProductsSlider = ({ allProducts = [] }) => {
  const route = useNavigate();
  const [
    addOrDeleteProductInFavourite,
    {
      isLoading: addOrDelIsLoading,
      isError: addOrDelIsError,
      error: addOrDelError,
    },
  ] = useAddOrRemoveFavouriteProductMutation();
  const {
    data: favItemsData,
    isError: isFavError,
    isLoading: isFavLoading,
    error: favError,
  } = useGetFavouritesProductsQuery();

  const favItems = favItemsData?.data ?? null;

  const handlepropa = (e) => {
    e.stopPropagation();
  };
  const handlefav = (ele) => {
    addOrDeleteProductInFavourite({ item_id: ele.id });
  };

  return (
    <section
      className="products ProductsaAll"
      style={{ position: "relative", marginTop: "-2rem" }}
    >
      <Container className="my-lg-4">
        <Swiper
          slidesPerView={5}
          spaceBetween={30}
          breakpoints={{
            0: {
              slidesPerView: 2.2,
              spaceBetween: 10,
            },
            760: {
              slidesPerView: 2.5,
              spaceBetween: 15,
            },
            990: {
              slidesPerView: 3.5,
            },
            1200: {
              slidesPerView: 4.5,
              spaceBetween: 30,
            },
            1500: {
              slidesPerView: 5.5,
            },
          }}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[FreeMode, Pagination, Navigation]}
          className="mySwiper my-lg-5"
        >
          {allProducts?.map((prod) => (
            <SwiperSlide
              key={prod.id}
              className={`mb-4 cat${prod.category.id} mix`}
            >
              <div
                className="product"
                onClick={() => route(`/Products/${prod.id}`)}
              >
                <div className="image_hold">
                  <img src={prod.icon} alt="product" className="mw-100" />
                </div>
                <div className="product_info">
                  <p className="image_desc">{prod.name}</p>
                  <div className="price">
                    <span
                      className={
                        prod.discount !== 0 ? "oldprice" : "currentPrice"
                      }
                    >
                      {prod.price}.00
                      <br />
                      {language === "ar" ? "ر.س" : "SAR"}
                    </span>
                    {prod.discount !== 0 && (
                      <>
                        <span className="currentPrice">
                          {prod.discount}.00 <br />
                          {language === "ar" ? "ر.س" : "SAR"}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="foote mt-3">
                    <ul className="product_option" onClick={handlepropa}>
                      <li
                        onClick={() => handlefav(prod)}
                        className={favItems
                          ?.map((el) =>
                            el.item_id === prod.id && !el.is_offer
                              ? "favicon"
                              : ""
                          )
                          .join("")}
                      >
                        {addOrDelIsLoading && <Spin />}
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button className="CartBtn">
                      <span className="IconContainer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 576 512"
                          fill="rgb(17, 17, 17)"
                          className="cart"
                        >
                          <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"></path>
                        </svg>
                      </span>
                      <p className="text_add_cart">
                        {language === "ar" ? "اضف الي السله" : "Add To Cart"}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
};

export default ProductsSlider;
