import {
  faDownload,
  faPenToSquare,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeleteFilled } from "@ant-design/icons";
import { ExclamationCircleFilled } from "@ant-design/icons";

import { Button, Card, Flex, message, Modal, Row, Space, Upload } from "antd";
// import InputNumber from 'rc-input-number';
import React, { useState } from "react";
import { Col, Container, FloatingLabel, Form } from "react-bootstrap";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import EditTip from "./EditTip";
import AddTip from "./AddTip";
import {
  useDeleteTipMutation,
  useGetAllCategoriesQuery,
  useGetAllTipsQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import ServerError from "../../components/utils/ServerError";

const { Meta } = Card;
const { confirm } = Modal;

function TipsControl() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);
  const [tipIdForEdit, setTipIdForEdit] = useState(null);

  const [modal, contextHolder] = Modal.useModal();

  const {
    data: allTipsData,
    isError: isAllTipsError,
    isLoading: isAllTipsLoading,
    error: allTipsError,
  } = useGetAllTipsQuery();

  const allTips = allTipsData?.data ?? null;

  const [
    deleteTip,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeleteTipMutation();

  const handleDeleteCategory = async (id) => {
    confirm({
      title: "هل تريد حقًا حذف هذه النصيحة؟",
      icon: <ExclamationCircleFilled />,
      content: "عند النقر فوق الزر موافق، ستتم إزالة هذه النصيحة ",
      async onOk() {
        try {
          const res = await deleteTip(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("تم حذف التلميح بنجاح");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  return (
    <section className="products section_padding mt-0 pt-0 position-relative">
      <h2 className="main_heading"> نصائح </h2>
      {isAllTipsLoading && <Loading />}
      {(isAllTipsError || delIsError) && <ServerError />}
      {contextHolder}
      <Flex gap="small" wrap="nowrap" className="justify-content-center mb-5">
        <Button type="primary" onClick={() => setOpenAddModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> إضافة نصائح{" "}
        </Button>
      </Flex>
      <Container className="my-5">
        <Row className="gap-5 justify-content-center">
          {allTips?.map((tip) => (
            <>
              {/* <Card
              key={tip.id}
              hoverable
              className="cat col-12 col-md-5 col-xl-3 border-2"
              cover={
                <p
                  className="p-3 line-coverage fs-6 pb-0 mb-0"
                  style={{ textAlign: "start" }}
                >
                  {tip.content}
                </p>
              }
              actions={[
                <DeleteFilled
                  key="setting"
                  style={{ fontSize: "20px" }}
                  onClick={() => handleDeleteCategory(tip.id)}
                />,
                <FontAwesomeIcon
                  style={{ fontSize: "20px" }}
                  icon={faPenToSquare}
                  onClick={() => {
                    setTipIdForEdit(tip.id);
                    setOpenEditModal(true);
                    setRerenderEditKey((prev) => prev + 1);
                  }}
                />,
              ]}
            >
              <Meta title={tip.author} />
              <Meta style={{ fontSize: "14px" }} title="Nutritionist" />
              <div className=" position-relative mt-2">
                <p className="m-0">{tip.author_job}</p>
              </div>
            </Card> */}

              <Card
                key={tip.id}
                hoverable
                className="col-3"
                loading={delIsLoading}
                cover={<img alt="slide-icon" src={tip.photo} height={200} />}
                actions={[
                  <DeleteFilled
                    key="setting"
                    size="large"
                    onClick={() => {
                      handleDeleteCategory(tip.id);
                    }}
                  />,
                  //   <Switch defaultChecked />
                ]}
              >
                <div className=" position-relative"></div>
              </Card>
            </>
          ))}
        </Row>
        <EditTip
          setOpenEditModal={setOpenEditModal}
          openEditModal={openEditModal}
          tipIdForEdit={tipIdForEdit}
          rerenderEditKey={rerenderEditKey}
        />

        <AddTip setOpenAddModal={setOpenAddModal} openAddModal={openAddModal} />
      </Container>
    </section>
  );
}

export default TipsControl;
