import { faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Flex, message, Modal, Row } from "antd";
import React, { useState } from "react";
import { Container } from "react-bootstrap";

import { DeleteFilled } from "@ant-design/icons";
import { ExclamationCircleFilled } from "@ant-design/icons";

import "./Brands.css";
import AddCategory from "./AddBrand";
import EditCategory from "./EditBrand";
import {
  useDeleteBrandMutation,
  useGetAllBrandsQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import ServerError from "../../components/utils/ServerError";

const { Meta } = Card;
const { confirm } = Modal;

function Categories() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [categoryIdForEdit, setCategoryIdForEdit] = useState(null);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);

  const {
    data: allCategoriessData,
    isError: isAllCategoriesError,
    isLoading: isAllCategoriesLoading,
    error: allCategoriesError,
  } = useGetAllBrandsQuery();

  const allCategories = allCategoriessData?.data ?? null;

  // console.log(allCategoriessData?.data);

  const [modal, contextHolder] = Modal.useModal();

  const [
    deleteCategory,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeleteBrandMutation();

  const handleDeleteCategory = async (id) => {
    confirm({
      title: "هل تريد حقًا حذف هذه العلامة التجارية؟",
      icon: <ExclamationCircleFilled />,
      content: "عند النقر على زر موافق، ستتم إزالة هذه العلامة التجارية ",
      async onOk() {
        try {
          const res = await deleteCategory(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("تم حذف العلامة التجارية بنجاح");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  return (
    <section className="products section_padding mt-0 pt-0 position-relative">
      <h2 className="main_heading"> علامات تجارية </h2>
      {isAllCategoriesLoading && <Loading />}
      {(isAllCategoriesError || delIsError) && <ServerError />}
      {contextHolder}
      <Flex gap="small" wrap="nowrap" className="justify-content-center mb-5">
        <Button type="primary" onClick={() => setOpenAddModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> أضف علامة تجارية{" "}
        </Button>
      </Flex>
      <Container className="my-5">
        <Row className="gap-5 justify-content-center">
          {allCategories?.map((category) => (
            <Card
              key={category.id}
              hoverable
              className="cat col-12 col-sm-6 col-md-3 col-lg-3"
              // loading={delIsLoading}
              cover={
                <img
                  alt="slide-icon"
                  className="img_cat"
                  src={category.photo}
                  style={{ maxHeight: "10rem", objectFit: "contain" }}
                />
              }
              actions={[
                <DeleteFilled
                  key="setting"
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    handleDeleteCategory(category.id);
                  }}
                />,
                <FontAwesomeIcon
                  onClick={() => {
                    setOpenEditModal(true);
                    setCategoryIdForEdit(category.id);
                    setRerenderEditKey((prev) => prev + 1);
                  }}
                  style={{ fontSize: "20px" }}
                  icon={faPenToSquare}
                />,
              ]}
            >
              <Meta title={category.name} />
              <div className=" position-relative"></div>
            </Card>
          ))}
        </Row>
        <EditCategory
          setOpenEditModal={setOpenEditModal}
          openEditModal={openEditModal}
          categoryIdForEdit={categoryIdForEdit}
          rerenderEditKey={rerenderEditKey}
        />

        {/* add product */}
        <AddCategory
          setOpenAddModal={setOpenAddModal}
          openAddModal={openAddModal}
        />
      </Container>
    </section>
  );
}

export default Categories;
