import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import {
  faMagnifyingGlass,
  faUser,
  faCartShopping,
  faArrowRightToBracket,
  faBabyCarriage,
} from "@fortawesome/free-solid-svg-icons";
import { IoIosArrowDown, IoIosGlobe } from "react-icons/io";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useEffect, useState } from "react";
//  mega menu on hover antd
//  mega menu on hover antd

import "./Navbar.css";
import { images } from "../../constants";
// Dropdown user
import Dropdown from "react-bootstrap/Dropdown";
import {
  useGetCartProductsQuery,
  useGetFavouritesProductsQuery,
  useGetLogedUserQuery,
  useLogoutMutation,
} from "../../redux/Service/api";
import {
  reduxLogin,
  reduxLogout,
  saveUserData,
} from "../../redux/Slices/authSlice";
import { language } from "../../language/Language";
import logo from "../../assets/logo.jpg";

const Navbar = () => {
  const [mobileMode, setMobileMode] = useState(false);
  const headerref = useRef();
  const toggleref = useRef();
  const navref = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // export const language = localStorage.getItem("language");

  // language === "ar" ? do something : default

  const handleclassopen = (...ele) => {
    ele.forEach((element) => {
      element.current.classList.toggle("open");
    });
  };
  const handlenav = (e) => {
    e.stopPropagation();
    handleclassopen(toggleref, navref);
    setMobileMode((prev) => !prev);
  };

  const {
    data,
    isError: getUserError,
    isLoading,
    refetch,
  } = useGetLogedUserQuery();
  const [logout, { isLoading: logoutLoading, isError }] = useLogoutMutation();
  const {
    data: cartData,
    isError: isCartError,
    isLoading: isCartLoading,
    error: cartError,
  } = useGetCartProductsQuery();

  const {
    data: favItems,
    isError: isFavError,
    isLoading: isFavLoading,
    error: favError,
  } = useGetFavouritesProductsQuery();

  const { isAuthenticated, userData } = useSelector((st) => st.auth);

  useEffect(() => {
    if (data && !getUserError) {
      dispatch(reduxLogin());
      dispatch(saveUserData(data?.data?.user));
    }
  }, [data, getUserError, dispatch]);

  const handleLogoutClick = async () => {
    localStorage.removeItem("userToken");
    try {
      await logout();

      dispatch(reduxLogout());
      refetch();
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <header ref={headerref}>
      <Container className="d-flex align-items-center position-relative">
        <nav className="flex_between flex-grow-1">
          <div className="info d-flex">
            <div
              className="toggle d-lg-none"
              ref={toggleref}
              onClick={handlenav}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  style={{ width: "51px" }}
                  className="p-0 hover_btn"
                >
                  <FontAwesomeIcon icon={faUser} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="">
                  <Dropdown.Item
                    onClick={() =>
                      navigate(isAuthenticated ? "/profile" : "/login")
                    }
                    className="d-flex flex-nowrap align-items-center justify-content-between gap-3 px-2"
                  >
                    {isAuthenticated ? data?.data.user.name : "profile"}
                    <FontAwesomeIcon
                      icon={faUser}
                      className="p-0 text-black-50"
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => navigate("/MyOrder")}
                    className="d-flex flex-nowrap align-items-center justify-content-between gap-3 px-2 mt-1"
                  >
                    <span>{language === "ar" ? "طلباتي" : "My Order"}</span>
                    <FontAwesomeIcon
                      className="p-0 text-black-50"
                      icon={faBabyCarriage}
                    />
                  </Dropdown.Item>
                  {isAuthenticated ? (
                    <Dropdown.Item
                      onClick={handleLogoutClick}
                      className="d-flex flex-nowrap align-items-center justify-content-between gap-3 px-2 mt-1"
                    >
                      <span>{language === "ar" ? "تسجيل خروج" : "logout"}</span>
                      <FontAwesomeIcon
                        icon={faArrowRightToBracket}
                        className="p-0 text-black-50"
                      />
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      onClick={() => navigate("/login")}
                      className="d-flex flex-nowrap align-items-center justify-content-between gap-3 px-2 mt-1"
                    >
                      <span>
                        {language === "ar" ? "تسجيل الدخول" : "Login"}
                      </span>
                      <FontAwesomeIcon
                        icon={faArrowRightToBracket}
                        className="p-0 text-black-50"
                      />
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <NavLink
              exact="true"
              className="class_cart d-none d-lg-flex"
              to="/Chart"
            >
              <span className="number">
                {isAuthenticated && cartData?.items?.length > 0
                  ? cartData?.items?.length
                  : 0}
              </span>
              <FontAwesomeIcon icon={faCartShopping} />
            </NavLink>
            <NavLink exact="true" to="/Favourite">
              <span className="number">
                {isAuthenticated && favItems?.data?.length > 0
                  ? favItems?.data?.length
                  : 0}
              </span>
              <FontAwesomeIcon icon={faHeart} />
            </NavLink>
            <NavLink exact="true" to="/" className="logo">
              <img style={{ height: "55px" }} src={logo} alt="logo" />
            </NavLink>
          </div>
          <NavLink
            exact="true"
            className="class_cart d-flex d-lg-none"
            to="/Chart"
          >
            <span className="number">
              {isAuthenticated && cartData?.items?.length > 0
                ? cartData?.items?.length
                : 0}
            </span>
            <FontAwesomeIcon icon={faCartShopping} />
          </NavLink>
          <div className="pages mt-2" ref={navref}>
            <NavLink exact="true" to="/">
              {language === "ar" ? "الصفحه الرئسية" : "Home"}
            </NavLink>
            <NavLink exact="true" to="/Products">
              {language === "ar" ? "منتجاتنا" : "Products"}
            </NavLink>

            {/* <NavLink exact="true" to="/Articles">
              {language === "ar" ? "مقالات" : "Articles"}{" "}
            </NavLink> */}
            {/* <NavLink exact="true" to="/WhiteFriday" className="sale">
              WhiteFriday
            </NavLink> */}
            <NavLink exact="true" to="/About">
              {language === "ar" ? "من نحن" : "About us"}
            </NavLink>
            <NavLink exact="true" to="/Contact">
              {language === "ar" ? "اتصل بنا" : "Contact us"}
            </NavLink>
            {userData?.role == "admin" && (
              <NavLink exact="true" to="/dashboard">
                {language == "ar" ? "لوحة التحكم" : "Dashboard"}
              </NavLink>
            )}
            <span
              style={{ display: "inline-block" }}
              className={`${mobileMode && "mx-2 mb-3"}`}
            >
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  style={{
                    width: "51px",
                    color: "#000",
                  }}
                  className="p-0"
                >
                  <IoIosGlobe style={{ height: "1.6rem", width: "2rem" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="">
                  <Dropdown.Item
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.setItem("language", "ar");
                      window.location.reload();
                    }}
                    className="d-flex flex-nowrap align-items-center justify-content-between py-2"
                    style={{ color: language == "ar" ? "#3c87c5" : "black" }}
                  >
                    <div className="">ar</div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.setItem("language", "en");
                      window.location.reload();
                    }}
                    className="d-flex flex-nowrap align-items-center justify-content-between  py-2"
                    style={{ color: language == "ar" ? "black" : "#3c87c5" }}
                  >
                    <div>en</div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </div>
        </nav>
      </Container>
    </header>
  );
};
export default Navbar;
