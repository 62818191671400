import "./Store.css";
import { language } from "../../language/Language";
const Store = () => {
  return (
    <section className="store flex_center" data-aos={"fade-up"}>
      <div className="store_info flex_center">
        <p className="store_name">الشمال الحديث للاجهزه</p>
        <address>
          {language === "ar"
            ? "شركة الشمال الحديثه للاجهزه الكهربائيه والمنزليه شركة متخصصة باعلي جوده واقل الاسعار داخل المملكه نحن نهتم بخدمه عملاء لامثيل لها مع استخدام الماركات العالميه والمحليه لنعدك بتجربة مميزة"
            : "Al-Shamal Modern Company for Electrical and Home Appliances is a company specialized in the highest quality and lowest prices within the Kingdom. We care about unparalleled customer service while using international and local brands to promise you a distinctive experience."}
        </address>
      </div>
    </section>
  );
};

export default Store;
