import React from "react";
import BannerImage from "../../components/BannerImage/BannerImage";
import ProductsSlider from "../ProductsSlider/ProductsSlider";
import { useGetAllCategoriesQuery } from "../../redux/Service/api";
import { useNavigate } from "react-router-dom";
import ServerError from "../../components/utils/ServerError";

const CategoriesWithProducts = () => {
  const route = useNavigate();
  const {
    data: allCategoriesData,
    isError: isCatError,
    isLoading: isCatLoading,
    error: catError,
  } = useGetAllCategoriesQuery();
  const allCategories = allCategoriesData?.data ?? null;
  if (isCatError) {
    return <ServerError />;
  }
  //
  return (
    <div>
      {allCategories?.map(
        (category) =>
          category.items?.length > 0 && (
            <div key={category.id}>
              <BannerImage img={category.photo} />
              <ProductsSlider allProducts={category.items} />
            </div>
          )
      )}
    </div>
  );
};

export default CategoriesWithProducts;
