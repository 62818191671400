import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation, Autoplay, Pagination, FreeMode } from "swiper";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addItemtofav, deleteFavItem } from "../../redux/action";
import "./featured-products.css";
import {
  useAddOrRemoveFavouriteProductMutation,
  useGetAllSpecialProductsQuery,
  useGetFavouritesProductsQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { Spin } from "antd";
import { language } from "../../language/Language";
import ServerError from "../../components/utils/ServerError";

const FeaturedProducts = () => {
  const route = useNavigate();
  // const favItems = useSelector((state) => state.handlechart.fav);
  const dispatch = useDispatch();

  const {
    data: allSpecialProductsData,
    isError: isSpecialProdsError,
    isLoading: isSpecialProdsLoading,
    error: specialprodsError,
  } = useGetAllSpecialProductsQuery();
  const allSpecialProducts = allSpecialProductsData?.data?.data ?? null;

  const [
    addOrDeleteProduct,
    {
      isLoading: addOrDelIsLoading,
      isError: addOrDelIsError,
      error: addOrDelError,
    },
  ] = useAddOrRemoveFavouriteProductMutation();
  const {
    data: favItemsData,
    isError: isFavError,
    isLoading: isFavLoading,
    error: favError,
  } = useGetFavouritesProductsQuery();

  const favItems = favItemsData?.data ?? null;

  const handlepropa = (e) => {
    e.stopPropagation();
  };
  const handlefav = (ele) => {
    addOrDeleteProduct({ item_id: ele.id });
  };
  if (isSpecialProdsError) {
    return <ServerError />;
  }
  return (
    <section
      className="Trending section_padding"
      data-aos={"fade-up"}
      style={{ position: "relative" }}
    >
      {allSpecialProducts?.length > 0 && (
        <h2 className="main_heading">
          {language === "ar" ? "المنتجات الخاصة" : "Special Products"}
        </h2>
      )}
      {isSpecialProdsLoading && <Loading />}

      <Container>
        <Swiper
          slidesPerView={5}
          spaceBetween={30}
          breakpoints={{
            0: {
              slidesPerView: 1.5,
              spaceBetween: 10,
            },
            760: {
              slidesPerView: 2.5,
              spaceBetween: 15,
            },
            990: {
              slidesPerView: 3.5,
            },
            1200: {
              slidesPerView: 4.5,
              spaceBetween: 30,
            },
            1500: {
              slidesPerView: 5.5,
            },
          }}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[FreeMode, Pagination, Navigation]}
          className="mySwiper"
        >
          {allSpecialProducts?.map((sp) => (
            <SwiperSlide key={sp.id}>
              <figure onClick={() => route(`/Products/${sp.id}`)}>
                <div
                  className="trend_image_hold"
                  style={{ position: "relative" }}
                >
                  <img src={sp.icon} alt="trending" />
                </div>
                <figcaption>
                  <p className="image_desc">{sp.name}</p>
                  <div className="price">
                    <span
                      className={
                        sp.discount !== 0 ? "oldprice" : "currentPrice"
                      }
                    >
                      {sp.price} {language === "ar" ? "ر.س" : "SAR"}
                    </span>
                    {sp.discount !== 0 && (
                      <span className="currentPrice">
                        {language === "ar" ? "ر.س" : "SAR"} {sp.discount}
                      </span>
                    )}
                  </div>
                  <ul className="trend_image_option" onClick={handlepropa}>
                    <li
                      onClick={() => handlefav(sp)}
                      className={favItems
                        ?.map((el) =>
                          el.item_id === sp.id && !el.is_offer ? "favicon" : ""
                        )
                        .join("")}
                    >
                      {addOrDelIsLoading && <Spin />}
                    </li>
                  </ul>
                </figcaption>
              </figure>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
};

export default FeaturedProducts;
