import React, { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import {
  Button,
  DatePicker,
  Image,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Upload,
} from "antd";

import {
  useEditCategoryMutation,
  useGetFullCategoryDetailsQuery,
} from "../../redux/Service/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const EditCategory = ({
  setOpenEditModal,
  openEditModal,
  categoryIdForEdit,
}) => {
  const [modalKey, setModalKey] = useState(0);

  // input number Edit
  const [nameEN, setNameEN] = useState("");
  const [nameAR, setNameAR] = useState("");

  const [file, setFile] = useState(null);

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const {
    data: allCategoryData,
    isError: isAllCategoryError,
    isLoading: isAllCategoryLoading,
    error: allCategoryError,
    refetch: refetchCategoryDetails,
  } = useGetFullCategoryDetailsQuery(categoryIdForEdit, {
    onQueryStarted: (query) => {},
  });

  const allCategoryDetails = allCategoryData?.data ?? null;

  useEffect(() => {
    setNameAR(allCategoryDetails?.name);
    setNameEN(allCategoryDetails?.name_en);
    setFile(allCategoryDetails?.photo);
  }, [allCategoryDetails]);

  const [
    editCategory,
    { isLoading: isEditLoading, isError: isEditError, error },
  ] = useEditCategoryMutation();
  const handleEditOffer = async () => {
    if (!nameAR) {
      message.error("الرجاء إضافة الاسم العربي");
      return;
    }
    if (!nameEN) {
      message.error("الرجاء إضافة الاسم الانجليزي");
      return;
    }
    if (!file) {
      message.error("يرجى تحميل ملف");
      return;
    }

    const formData = new FormData();
    formData.append("name", nameAR);
    formData.append("name_en", nameEN);
    if (typeof file !== "string") {
      formData.append("photo", file);
    }

    try {
      const res = await editCategory({ id: categoryIdForEdit, formData });
      setFile(null);
      setNameAR("");
      setNameEN("");
      setModalKey((prev) => prev + 1);

      res.data.message
        ? message.info(res.data.message)
        : message.success("تمت إضافة الفئة بنجاح");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error adding Category:", error);
      message.error("حدث خطأ أثناء إضافة الفئة. يرجى المحاولة مرة أخرى.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="تعديل النوع"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={500}
      footer
    >
      {isAllCategoryLoading && (
        <p>
          <Spin size="small" style={{ marginRight: "0.2rem" }} />
          loading details...
        </p>
      )}
      {isAllCategoryError && (
        <p className="text-danger">Something went error</p>
      )}
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="الاسم بالعربي"
                value={nameAR}
                onChange={(e) => setNameAR(e.target.value)}
              />
              <Input
                placeholder="الاسم باللغه الانجليزيه"
                value={nameEN}
                onChange={(e) => setNameEN(e.target.value)}
              />
              <Col className="gap-3 d-flex flex-column col-6">
                <Image src={file} loading={isAllCategoryLoading} />
              </Col>
              <Row className="g-2">
                <Col className="gap-3 d-flex flex-column col-6">
                  <Upload
                    customRequest={({ file, onSuccess, onError }) => {
                      try {
                        onSuccess();
                      } catch (error) {
                        console.error("Error handling file:", error);
                      }
                    }}
                    onChange={({ fileList }) => {
                      handleFileChange(fileList);
                    }}
                    listType="picture"
                    maxCount={1}
                  >
                    <Button
                      loading={false}
                      icon={<FontAwesomeIcon icon={faDownload} />}
                    >
                      تغيير الصورة (الحد الأقصى: 1)
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-2">
            <Button
              type="primary"
              onClick={handleEditOffer}
              loading={isEditLoading}
            >
              تعديل
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default EditCategory;
