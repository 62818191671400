import { language } from "../../language/Language";
import "./Identification.css";

function Identification() {
  return (
    <div className="container d-flex justify-content-center Identification">
      <div
        style={{
          width: "600px",
          maxWidth: "100%",
          margin: "40px 0",
          backgroundColor: "blue",
        }}
        className="text-white d-flex justify-content-center align-items-center flex-column py-3 px-5 rounded-2"
      >
        <h1 className="fs-5 fw-bold">
          {language === "ar"
            ? "مؤسسه الشمال الحديثه للاجهزه"
            : "Al-Shamal Modern Equipment Corporation"}
        </h1>
        <h3 className="fs-5" style={{ color: "yellow" }}>
          {language === "ar"
            ? "جده حي الصفا شارع الاربعين"
            : "Jeddah, Al-Safa District, Al-Arbaeen Street"}
        </h3>
        <h4 className="text-center fs-6">
          <strong>
            {language === "ar"
              ? "رقم التسجيل الضريبي"
              : "Tax registration number"}
            : 311729361300006
          </strong>
        </h4>
      </div>
    </div>
  );
}

export default Identification;
