import { faHeart, faStar } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regfaHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  addItemtofav,
  addItemtochart,
  deleteFavItem,
} from "../../redux/action";
import "./offer.css";
import { useEffect } from "react";
import {
  useAddOrRemoveFavouriteProductMutation,
  useAddToCartMutation,
  useGetFavouritesProductsQuery,
  useGetOneOfferQuery,
  useGetOneProductQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { Image, message, Spin } from "antd";
import ServerError from "../../components/utils/ServerError";
import { language } from "../../language/Language";

const Offer = () => {
  const [productCount, setProductCount] = useState(1);
  // const favItems = useSelector((state) => state.handlechart.fav);
  const dispatch = useDispatch();
  const { id } = useParams();
  const svgicon = useRef();

  const [messageApi, contextHolder] = message.useMessage();
  const info = (message) => {
    messageApi.open({
      type: `${message ? "danger" : "success"}`,
      content: `${message ? message : "item is added"}`,

      style: {
        marginTop: "10vh",
      },
    });
  };

  const {
    data: productData,
    isError: isProdError,
    isLoading: isProdLoading,
    error: prodError,
  } = useGetOneOfferQuery(id);

  const [
    addToCart,
    {
      isLoading: addIsLoading,
      isError: addIsError,
      error: addError,
      isSuccess: addIsSuccess,
    },
  ] = useAddToCartMutation();

  const product = productData?.data ?? null;

  const [
    addOrDeleteProduct,
    {
      isLoading: addOrDelIsLoading,
      isError: addOrDelIsError,
      error: addOrDelError,
    },
  ] = useAddOrRemoveFavouriteProductMutation();
  const {
    data: favItemsData,
    isError: isFavError,
    isLoading: isFavLoading,
    error: favError,
  } = useGetFavouritesProductsQuery();

  const favItems = favItemsData?.data ?? null;

  let handlefav = (ele) => {
    addOrDeleteProduct({ offer_id: ele.id });
  };

  const handleAddToCart = async () => {
    try {
      const res = await addToCart({
        offer_id: product?.id,
        count: productCount,
      });

      info(res.data.message);

      setProductCount(1);
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };
  return (
    <section
      className="Product section_padding"
      style={{ position: "relative" }}
    >
      {contextHolder}
      <Container>
        {(isProdLoading || addIsLoading) && <Loading />}
        {isProdError && <ServerError />}
        {
          <Row>
            <Col xs={12} md={6} className="flex_center">
              <div className="Product_image_holder">
                <Image
                  src={product?.icon}
                  alt="product"
                  style={{ minWidth: "25rem", maxHeight: "30rem" }}
                />
              </div>
            </Col>
            <Col xs={12} md={6} className="flex_center mt-sm-4">
              <div className="product_info flex_center flex-column gap-2">
                {/* <p className="cat">{product?.category.name}</p> */}
                <p className="rating">Offer</p>
                <p className="name">{product?.title}</p>
                <p>
                  {" "}
                  {product?.from} - {product?.to}{" "}
                </p>
                <div className="price">
                  <span
                    className={product?.discount ? "oldprice" : "currentPrice"}
                  >
                    {language === "ar" ? "ر.س" : "SAR"} {product?.price}
                  </span>
                  {product?.discount && (
                    <span className="currentPrice">
                      {language === "ar" ? "ر.س" : "SAR"} {product?.discount}
                    </span>
                  )}
                </div>
                {/* <p className="text text-center">{product?.details}</p> */}
                <div>
                  <span
                    className="mx-3 fw-bold fs-2 py-2 px-3 rounded-2"
                    style={{ border: "1px solid black", cursor: "pointer" }}
                    onClick={() => setProductCount((prev) => prev + 1)}
                  >
                    +
                  </span>
                  <span className=" fw-bold fs-2">{productCount}</span>
                  <span
                    className="mx-3 fw-bold fs-2 py-2 px-3 rounded-2"
                    style={{ border: "1px solid black", cursor: "pointer" }}
                    onClick={() =>
                      setProductCount((prev) => (prev > 1 ? prev - 1 : 1))
                    }
                  >
                    -
                  </span>
                </div>
                <span
                  className="fav position-relative"
                  onClick={() => handlefav(product)}
                >
                  {addOrDelIsLoading && <Spin />}
                  {favItems?.find(
                    (el) => el.item_id === product?.id && el.is_offer
                  ) ? (
                    <FontAwesomeIcon icon={faHeart} />
                  ) : (
                    <FontAwesomeIcon icon={regfaHeart} />
                  )}
                </span>
                <div className="linkstochart d-flex gap-2">
                  <button className="addtochart" onClick={handleAddToCart}>
                    Add To Cart
                  </button>
                  <Link className="gotochart custom_button" to="/Chart">
                    Go To Cart
                  </Link>
                </div>
                <Link className="backtopro" to="/Products">
                  Go To Products
                </Link>
              </div>
            </Col>
          </Row>
        }
      </Container>
    </section>
  );
};

export default Offer;
