import { Layout, Menu } from "antd";
import React, { useLayoutEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./style.css";
import { images } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowsToEye,
  faCartPlus,
  faPercent,
  faShop,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const { Content, Footer, Sider } = Layout;

const sidebarTabs = [
  {
    key: "users",
    label: "المستخدمين",
    icon: <FontAwesomeIcon icon={faUserGroup} />,
  },
  {
    key: "category",
    label: "الانواع",
    icon: <FontAwesomeIcon icon={faShop} />,
  },
  {
    key: "Brands",
    label: "العلامات التجارية",
    icon: <FontAwesomeIcon icon={faShop} />,
  },
  {
    key: "products",
    label: "المنتجات",
    icon: <FontAwesomeIcon icon={faShop} />,
  },
  {
    key: "orders",
    label: "الطلبات",
    icon: <FontAwesomeIcon icon={faCartPlus} />,
  },
  {
    key: "articles",
    label: "الشهادات",
    icon: <FontAwesomeIcon icon={faNewspaper} />,
  },
  {
    key: "tips",
    label: "نصائح",
    icon: <FontAwesomeIcon icon={faNewspaper} />,
  },
  {
    key: "specialOffers",
    label: "عروض خاصة",
    icon: <FontAwesomeIcon icon={faPercent} />,
  },
  {
    key: "AddSlider",
    label: "إضافة بنارات",
    icon: <FontAwesomeIcon icon={faArrowsToEye} />,
  },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const { userData } = useSelector((st) => st.auth);
  const [siderCollapsed, setSiderCollapsed] = React.useState(false);

  useLayoutEffect(() => {
    if (userData?.role !== "admin") {
      navigate("/");
    }
  }, []);

  const handleTabClick = (event) => {
    const clickedTabKey = event.key;
    navigate(`/dashboard/${clickedTabKey}`);
  };
  return (
    <Layout className="dashboard" hasSider>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {}}
        onCollapse={(collapsed, type) => {
          setSiderCollapsed(collapsed);
          if (type === "clickTrigger" && !collapsed)
            setSiderCollapsed(!collapsed);
        }}
        style={{
          position: "fixed",
          height: "100vh",
          top: 0,
          bottom: 0,
          zIndex: 1000,
        }}
      >
        <div
          className="logo"
          style={{ color: "white", cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <img
            src={images.logo}
            alt="logo"
            style={{ width: "100%", height: "10rem" }}
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["users"]}
          items={sidebarTabs}
          onClick={handleTabClick}
        />
      </Sider>
      <Layout>
        <Content
          style={{ margin: !siderCollapsed ? "0px 0 0 12rem" : "initial" }}
        >
          <div className="site-layout-background content">
            <Outlet />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          prosoft
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Dashboard;
