import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./SpecialOffers";
import { Flex, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import AOS from "aos";
import EditOffer from "./EditOffer";
import AddOffer from "./AddOffer";
import {
  useDeleteOfferMutation,
  useGetAllOffersQuery,
} from "../../redux/Service/api";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Loading from "../../components/utils/Loading";
import ServerError from "../../components/utils/ServerError";
AOS.init({
  once: false,
});
const { confirm } = Modal;
function SpecialOffers() {
  const navigate = useNavigate();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [offerIdForEdit, setOfferIdForEdit] = useState(null);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);

  const [modal, contextHolder] = Modal.useModal();

  // /* modal => Delete the product */

  const handlepropa = (e) => {
    e.stopPropagation();
  };

  const {
    data: allOfferssData,
    isError: isAllOffersError,
    isLoading: isAllOffersLoading,
    error: allOffersError,
  } = useGetAllOffersQuery();

  const allOffers = allOfferssData?.data ?? null;

  const [
    deleteOffer,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeleteOfferMutation();

  const handleDeleteOffer = async (id) => {
    confirm({
      title: "هل تريد حقا حذف هذا العرض?",
      icon: <ExclamationCircleFilled />,
      content: "عند الضغط على زر موافق، سيتم حذف هذا العرض",
      async onOk() {
        try {
          const res = await deleteOffer(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("تم حذف العرض بنجاح");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  return (
    <section className="products section_padding position-relative">
      <h2 className="main_heading">عروض خاصة</h2>
      {isAllOffersLoading && <Loading />}
      {(isAllOffersError || delIsError) && <ServerError />}
      {contextHolder}
      <Flex gap="small" wrap="nowrap" className="justify-content-center mb-5">
        <Button onClick={() => setOpenAddModal(true)}>
          <FontAwesomeIcon icon={faPlus} />
          أضف العروض{" "}
        </Button>
      </Flex>
      <Container className="my-5">
        <Row>
          {allOffers?.map((offer) => (
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={offer.id}
              className={`mb-4 mix`}
            >
              <div
                className="product"
                onClick={() => navigate(`/offer/${offer.id}`)}
                data-aos="fade-up"
              >
                <div className="image_hold">
                  <img src={offer.icon} alt="offferImage" className="mw-100" />
                  <ul className="product_optionn d-flex" onClick={handlepropa}>
                    <li
                      onClick={() => {
                        setOpenEditModal(true);
                        setOfferIdForEdit(offer.id);
                        setRerenderEditKey((prev) => prev + 1);
                      }}
                    ></li>
                    <li onClick={() => handleDeleteOffer(offer.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </li>
                  </ul>
                </div>
                <div className="product_info">
                  <p className="image_desc mb-0">{offer.title}</p>
                  <div className="fw-bold">
                    <span>{offer.from}</span>{" "}
                    <span className="mx-2 fw-bold"> : </span>{" "}
                    <span>{offer.to}</span>
                  </div>
                  <div className="price">
                    <span className="currentPrice">ر.س {offer.price}</span>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <EditOffer
          setOpenEditModal={setOpenEditModal}
          openEditModal={openEditModal}
          offerIdForEdit={offerIdForEdit}
          rerenderEditKey={rerenderEditKey}
        />

        <AddOffer
          setOpenAddModal={setOpenAddModal}
          openAddModal={openAddModal}
        />
      </Container>
    </section>
  );
}

export default SpecialOffers;
