import "./Landing.css";

import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Scrollbar, A11y, EffectFade, Autoplay } from "swiper";

import "swiper/css/bundle";
import "swiper/css";
import { useNavigate } from "react-router-dom";
import { useGetSlidesQuery } from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { language } from "../../language/Language";
import HomeBanner from "../../assets/HomeBanner.jpg";

const Landing = () => {
  const route = useNavigate();
  const {
    data: allSlidesData,
    isError: isSlidesError,
    isLoading: isSlidesLoading,
    error: slidesError,
  } = useGetSlidesQuery();
  const allSlides = allSlidesData?.data ?? null;

  return (
    <section style={{ position: "relative" }}>
      <Swiper
        modules={[Navigation, Scrollbar, A11y, EffectFade, Autoplay]}
        navigation
        effect="fade"
        loop="true"
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
      >
        {allSlides ? (
          allSlides?.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div className="text">
                {!slide.title === "." && <p className="title">{slide.title}</p>}
                <button
                  className="custom_button"
                  onClick={() => route("/products")}
                >
                  {language === "ar" ? "تسوق الآن" : "Shop Now"}
                </button>
              </div>
              <img src={slide.photo} alt="slide1" />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <Loading />
            <img
              src="https://almtkamelstore.prosofteg.com/photo/sliders/NClDhTXJaBfqCd5Lv7b1KpnzWIpFtrwDRpbAcsJx.jpg"
              alt="loading..."
            />
          </SwiperSlide>
        )}
      </Swiper>
    </section>
  );
};

export default Landing;
