import {
  Button,
  Dropdown,
  Flex,
  Input,
  message,
  Modal,
  Space,
  Table,
} from "antd";
import { useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { ExclamationCircleFilled } from "@ant-design/icons";

import {
  useAddDeliveryPriceMutation,
  useAdminGetAllUserQuery,
  useCancelOrderMutation,
  useDeleteUserMutation,
  useGetAllOrdersQuery,
  useSwapUserRoleMutation,
  useToNextOrderStatusMutation,
  useToPreviousOrderStatusMutation,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { Link } from "react-router-dom";
import ServerError from "../../components/utils/ServerError";
import { exportToExcel } from "../../components/utils/ExportToExcel";

const { confirm } = Modal;

const UsersControl = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [deliveryPrice, setDeliveryPrice] = useState(null);
  const [isAddDeliveryModalOpen, setIsAddDeliveryModalOpen] = useState(false);
  const [idForAddDelivery, setIdForAddDelivery] = useState(null);
  const [modalKey, setModalKey] = useState(0);

  const [
    toNextOrderStatus,
    {
      isLoading: toNextStatusIsLoading,
      isError: toNextStatusIsError,
      error: toNextStatusError,
    },
  ] = useToNextOrderStatusMutation();
  const [
    toPrevOrderStatus,
    {
      isLoading: toPrevStatusIsLoading,
      isError: toPrevStatusIsError,
      error: toPrevStatusError,
    },
  ] = useToPreviousOrderStatusMutation();

  const [
    addDeliveryPrice,
    { isLoading: isDeliveryPiceLoading, isError: isDeliveryPiceError, error },
  ] = useAddDeliveryPriceMutation();

  const [
    cancelOrder,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useCancelOrderMutation();
  const handleOrderCanceled = async (id) => {
    confirm({
      title: "Do you really want to cancel this order?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this order will be canceled",
      async onOk() {
        try {
          const res = await cancelOrder(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("order canceled successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  const {
    data: allOrdersData,
    isError: isOrdersError,
    isLoading: isOrdersLoading,
    error: allOrdersError,
  } = useGetAllOrdersQuery();
  const allOrders = allOrdersData?.data ?? null;

  const data = allOrders
    ?.slice()
    .reverse()
    .map((order) => {
      return {
        key: order.id,
        orderID: order.id,
        totalPrice: order.total,
        type: order.payment_type,
        status: order,
        createdAt: order.created_at,
        deliveryPrice: order.delivery_price ?? 0,
        //
        itemName: order.items,
        itemCount: order.items,
        itemPrice: order.items,
        itemTotal: order.items,
        //
        username: order.client.name,
        useremail: order.client.email,
        userphone: order.client.phone,
        useraddress: order.address.full_address,
      };
    });
  console.log(data);
  const flattenData = () => {
    const flatData = [];
    allOrders.forEach((order) => {
      order.items.forEach((item) => {
        flatData.push({
          key: order.id,
          orderID: order.id,
          totalPrice: order.total,
          type: order.payment_type,
          status: order.status,
          createdAt: order.created_at,
          deliveryPrice: order.delivery_price ?? 0,
          itemName: item.name,
          itemCount: item.count,
          itemPrice: item.price,
          itemTotal: item.total,
          username: order.client.name,
          useremail: order.client.email,
          userphone: order.client.phone,
          useraddress: order.address.full_address,
        });
      });
    });
    return flatData;
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSearchPropsForStatus = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      // console.log(value, record, dataIndex);
      if (value.toLowerCase().includes("c")) {
        return record[dataIndex].is_canceled === 1;
      }
      return (
        record[dataIndex].is_canceled !== 1 &&
        record[dataIndex].status
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      console.log(text);
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      );
    },
  });

  const columns = [
    {
      title: "معلومات الطلب",
      children: [
        {
          title: "التسلسل",
          dataIndex: "orderID",
          key: "orderID",
          ...getColumnSearchProps("orderID"),
          sorter: (a, b) => a.orderID - b.orderID,
          sortDirections: ["descend", "ascend"],
          width: 110,
          color: "red",
        },
        {
          title: "السعر الإجمالي",
          dataIndex: "totalPrice",
          key: "totalPrice",
          ...getColumnSearchProps("totalPrice"),
          sorter: (a, b) => a.totalPrice - b.totalPrice,
          sortDirections: ["descend", "ascend"],
          width: 130,
        },
        {
          title: "نوع الدفع",
          dataIndex: "type",
          key: "type",
          ...getColumnSearchProps("type"),
          width: 100,
        },
        {
          title: "الحاله",
          dataIndex: "status",
          key: "status",
          ...getColumnSearchPropsForStatus("status"),
          width: 130,
          render: (dataRender) => {
            const textToHighlight = dataRender.is_canceled
              ? "canceled"
              : dataRender.status;

            return searchedColumn === "status" ? (
              <Highlighter
                highlightStyle={{
                  backgroundColor: "#ffc069",
                  padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={
                  textToHighlight ? textToHighlight.toString() : ""
                }
              />
            ) : dataRender.is_canceled ? (
              <span>canceled</span>
            ) : (
              <span>{dataRender.status}</span>
            );
          },
        },
        {
          title: "سعر التسليم",
          dataIndex: "deliveryPrice",
          key: "deliveryPrice",
          ...getColumnSearchProps("deliveryPrice"),
          width: 130,
        },
        {
          title: "انشاء في",
          dataIndex: "createdAt",
          key: "createdAt",
          width: 130,
        },
      ],
    },
    {
      title: "تفاصيل عناصر الطلب",
      children: [
        {
          title: "اسم العناصر",
          key: "itemName",
          dataIndex: "itemName",
          width: 120,
          render: (data) => {
            return (
              <div className="d-flex flex-column justify-content-between text-center">
                {data?.map((item, idx) => {
                  return (
                    <Link
                      key={`${idx}-key`}
                      className="my-1 fs-6"
                      to={
                        item.is_offer
                          ? `/offer/${item.offer_id}`
                          : `/Products/${item.item_id}`
                      }
                    >
                      {item.name ? item.name : "offer"}
                    </Link>
                  );
                })}
              </div>
            );
          },
        },
        {
          title: "عدد العناصر",
          dataIndex: "itemCount",
          key: "itemCount",
          width: 70,
          render: (data) => {
            return (
              <div className="d-flex flex-column justify-content-between text-center">
                {data?.map((item, idx) => {
                  return (
                    <span key={`${idx}-key2`} className="my-1 fs-6">
                      {item.count}
                    </span>
                  );
                })}
              </div>
            );
          },
        },
        {
          title: "سعر وحدة العناصر",
          dataIndex: "itemPrice",
          key: "itemPrice",
          width: 120,
          render: (data) => {
            return (
              <div className="d-flex flex-column justify-content-between text-center">
                {data?.map((item, idx) => {
                  return (
                    <span key={`${idx}-key3`} className="my-1 fs-6">
                      {item.price}
                    </span>
                  );
                })}
              </div>
            );
          },
        },
        {
          title: "السعر الإجمالي للعناصر",
          dataIndex: "itemTotal",
          key: "itemTotal",
          width: 150,
          render: (data) => {
            return (
              <div className="d-flex flex-column justify-content-between text-center">
                {data?.map((item, idx) => {
                  return (
                    <span key={`${idx}-key4`} className="my-1 fs-6">
                      {item.count * item.price}
                    </span>
                  );
                })}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "معلومات المستخدم",
      children: [
        {
          title: "اسم المستخدم",
          dataIndex: "username",
          key: "username",
          ...getColumnSearchProps("username"),
          width: 150,
        },
        {
          title: "البريد الإلكتروني",
          dataIndex: "useremail",
          key: "useremail",
          ...getColumnSearchProps("useremail"),
          width: 190,
        },
        {
          title: "الهاتف",
          dataIndex: "userphone",
          key: "userphone",
          ...getColumnSearchProps("userphone"),
          width: 130,
        },
        {
          title: "العنوان",
          dataIndex: "useraddress",
          key: "useraddress",
          ...getColumnSearchProps("useraddress"),
          width: 170,
        },
      ],
    },
    {
      title: "الإجراءات",
      key: "actions",
      // fixed: "right",
      render: (data) => {
        return (
          <div className="d-flex gap-2 flex-column">
            <Button
              type="primary"
              ghost
              onClick={() => handleToTheNextOrderStatusClick(data.key)}
            >
              إلى حالة الطلب التالي
            </Button>
            <Button
              type="primary"
              ghost
              onClick={() => handleToThePreviousOrderStatusClick(data.key)}
            >
              إلى حالة الطلب السابقة
            </Button>
            <Button
              type="primary"
              ghost
              danger
              onClick={() => {
                setIdForAddDelivery(data.key);
                setIsAddDeliveryModalOpen(true);
              }}
            >
              أضف سعر التوصيل
            </Button>
            <Button
              type="primary"
              // ghost
              danger
              onClick={() => {
                handleOrderCanceled(data.key);
              }}
            >
              تم إلغاء الطلب{" "}
            </Button>
          </div>
        );
      },
      width: 200,
    },
  ];

  const handleToTheNextOrderStatusClick = async (id) => {
    confirm({
      title: "هل تريد التبديل إلى حالة الطلب التالية؟",
      icon: <ExclamationCircleFilled />,
      content: "عند الضغط على زر موافق، سيتم تحديث الحالة",
      async onOk() {
        try {
          const res = await toNextOrderStatus(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("تم تغيير الحالة بنجاح");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };
  const handleToThePreviousOrderStatusClick = async (id) => {
    confirm({
      title: "هل تريد التبديل إلى حالة الطلب السابقة؟",
      icon: <ExclamationCircleFilled />,
      content: "عند الضغط على زر موافق، سيتم تحديث الحالة",
      async onOk() {
        try {
          const res = await toPrevOrderStatus(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("تم تغيير الحالة بنجاح");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };
  const handleAddDeliveryPriceClick = async () => {
    if (!deliveryPrice) {
      message.error("الرجاء إدخال سعر التسليم");
      return;
    }
    const formData = new FormData();
    formData.append("delivery_price", deliveryPrice);
    try {
      const res = await addDeliveryPrice({ id: idForAddDelivery, formData });
      setDeliveryPrice(null);

      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("تمت إضافة سعر التسليم بنجاح");
      setIsAddDeliveryModalOpen(false);
    } catch (error) {
      console.error("Error adding delivery price:", error);
      message.error("حدث خطأ أثناء إضافة سعر التسليم. يرجى المحاولة مرة أخرى.");
    }
  };

  return (
    <>
      <Modal
        key={modalKey}
        title="أضف سعر التوصيل"
        open={isAddDeliveryModalOpen}
        confirmLoading={isDeliveryPiceLoading}
        onOk={() => {
          handleAddDeliveryPriceClick();
        }}
        onCancel={() => {
          setIsAddDeliveryModalOpen(false);
        }}
      >
        <Input
          type="number"
          placeholder="سعر التسليم"
          value={deliveryPrice}
          onChange={(e) => setDeliveryPrice(e.target.value)}
        />
      </Modal>
      <article className="users position-relative">
        <article className="container users_container">
          <h2 className="main_heading">طلبات</h2>
          {isOrdersError && <ServerError />}
          <Button
            type="primary"
            className="mb-2"
            onClick={() => exportToExcel(flattenData(), "orders")}
            disabled={isOrdersLoading}
          >
            تصدير إلى إكسل
          </Button>
          <Table
            columns={columns}
            dataSource={data}
            loading={isOrdersLoading}
            bordered
            rowClassName={"rowName"}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: [9, 10, 20, 30, 50],
              showTotal: (total) => `العدد الكلي: ${total}`,
            }}
            scroll={{ y: 400, x: 500 }}
          />
        </article>
      </article>
    </>
  );
};
export default UsersControl;
