import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import "./Contact.css";
import AOS from "aos";
import { language } from "../../language/Language";
AOS.init({
  once: true,
});

const Contact = () => {
  const route = useNavigate();
  return (
    <section className="contact">
      <section className="contact_img"></section>
      <main>
        <Container>
          <Row>
            <Col xs={12} md={6} className="pe-0 col" data-aos="fade-up">
              <div className="contact_message">
                <h4>
                  {language === "ar" ? "أرسل لنا رسالة" : "Send Us A Message"}
                </h4>
                <form action="#">
                  <input
                    type="text"
                    placeholder={
                      language === "ar"
                        ? "عنوان بريدك  الإلكتروني"
                        : "Your Email Address"
                    }
                  />
                  <textarea
                    name="Help"
                    cols="30"
                    rows="10"
                    placeholder={
                      language === "ar"
                        ? "كيف يمكن أن نساعد ؟"
                        : "How Can We Help ?"
                    }
                  ></textarea>
                  <button
                    className="custom_button w-100"
                    onClick={() => route("/")}
                  >
                    {language === "ar" ? "يُقدِّم" : "Submit"}
                  </button>
                </form>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              className="ps-0 col flex_center"
              data-aos="fade-up"
            >
              <div className="contact_address">
                {/* <div className="address">
                  <FontAwesomeIcon icon={faLocationDot} />
                  <div>
                    <p>{language === "ar" ? "عنوان" : "Address"}</p>
                    <p className="address_text">
                      {language === "ar"
                        ? "مركز eShop في الطابق التاسع، X شارع فيصل، الجيزة، مصر"
                        : "eShop Center at 9th floor, X Faisal St, Giza, Egypt"}
                    </p>
                  </div>
                </div> */}
                <div>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <div>
                    <p>{language === "ar" ? "دعم البيع" : "Sale Support"}</p>
                    <p>INFO@modern-north-sa.com</p>
                    <p>SEALS@modern-north-sa.com</p>
                  </div>
                </div>
                <div>
                  <FontAwesomeIcon icon={faPhone} />
                  <div className="w-100">
                    <p>{language === "ar" ? "دعونا نتحدث" : "Lets Talk"}</p>
                    <Table bordered hover variant="">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            {language === "ar" ? "رقم الهاتف" : "Phone Number"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>+966555981921</td>
                        </tr>
                        {/* <tr>
                          <td>2</td>
                          <td>01277778909</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>01200007517</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>01200039200</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>01210333377</td>
                        </tr> */}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </section>
  );
};

export default Contact;
