import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Summary.css";
import { language } from "../../language/Language";

const Summary = () => {
  const summref = useRef();
  useEffect(() => {
    let myps = [...summref.current.children];
    let i = 0;
    setInterval(() => {
      myps.forEach((ele) => {
        ele?.classList.remove("active");
      });
      myps[i]?.classList.add("active");
      i++;
      if (i === myps.length) {
        i = 0;
      }
    }, 5000);
  }, []);
  return (
    <>
      <div className="summary" ref={summref}>
        <p>
          {language === "ar" ? "شحن داخل المملكه" : "national Shipping"}{" "}
          <Link to="/products">
            {" "}
            {language === "ar" ? " تسوق الان " : " Shop Now "}{" "}
          </Link>
        </p>
        <p className="active">
          {language === "ar"
            ? "الشمال الحديثه للاجهزة"
            : "الشمال الحديثه للاجهزة"}
        </p>
        <p>
          {language === "ar" ? "عروض منزليه باقل الاسعار" : "Home Offers"}{" "}
          <Link to="/">{language === "ar" ? "ابحث عنا" : "Search For Us"}</Link>
        </p>
        <p> {language === "ar" ? "متعه التسوق" : "Affordable Shopping"}</p>
      </div>
    </>
  );
};

export default Summary;
