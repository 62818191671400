import { faHeart, faStar } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regfaHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import "./Product.css";

import {
  useAddOrRemoveFavouriteProductMutation,
  useAddToCartMutation,
  useGetFavouritesProductsQuery,
  useGetOneProductQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { Image, message, Spin } from "antd";
import { language } from "../../language/Language";
import ServerError from "../../components/utils/ServerError";

const Product = () => {
  const [productCount, setProductCount] = useState(1);
  const { isAuthenticated } = useSelector((st) => st.auth);
  const { id } = useParams();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const info = (message) => {
    messageApi.open({
      type: `${message ? "danger" : "success"}`,
      content: `${message ? message : "item is added"}`,

      style: {
        marginTop: "10vh",
      },
    });
  };

  const {
    data: productData,
    isError: isProdError,
    isLoading: isProdLoading,
    error: prodError,
  } = useGetOneProductQuery(id);

  console.log(productData);

  const [
    addToCart,
    {
      data: addData,
      isLoading: addIsLoading,
      isError: addIsError,
      error: addError,
      isSuccess: addIsSuccess,
    },
  ] = useAddToCartMutation();

  const product = productData?.data ?? null;
  const [
    addOrDeleteProduct,
    {
      isLoading: addOrDelIsLoading,
      isError: addOrDelIsError,
      error: addOrDelError,
    },
  ] = useAddOrRemoveFavouriteProductMutation();
  const {
    data: favItemsData,
    isError: isFavError,
    isLoading: isFavLoading,
    error: favError,
  } = useGetFavouritesProductsQuery();

  const favItems = favItemsData?.data ?? null;

  let handlefav = (ele) => {
    addOrDeleteProduct({ item_id: ele.id });
  };

  const handleAddToCart = async () => {
    if (!isAuthenticated) {
      navigate("/login");
    }
    try {
      const res = await addToCart({
        item_id: product?.id,
        count: productCount,
      });
      info(res.data.message);

      setProductCount(1);
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };
  return (
    <section
      className="Product section_padding"
      style={{ position: "relative" }}
    >
      {contextHolder}
      <Container>
        {(isProdLoading || addIsLoading) && <Loading />}
        {isProdError && <ServerError />}
        {
          <Row>
            <Col xs={12} md={6} className="flex_center">
              <div className="Product_image_holder d-flex flex-column gap-2">
                <div className="Product_image-holder">
                  <Image
                    src={product?.icon}
                    alt="product"
                    style={{ minWidth: "25rem", maxHeight: "30rem" }}
                  />
                </div>
                {product?.photos?.length > 0 && (
                  <div className="d-flex gap-1">
                    {product?.photos?.map((photo) => (
                      <Image
                        key={photo.path}
                        src={photo.path}
                        alt="product"
                        className="mx-1 small-img"
                        style={{ width: "5rem", height: "5rem" }}
                      />
                    ))}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12} md={6} className="flex_center mt-sm-2">
              <div className="product_info flex_center flex-column gap-2">
                <p className="cat">{product?.category.name}</p>
                <p className="name">{product?.name}</p>

                <div className="price">
                  <span
                    className={product?.discount ? "oldprice" : "currentPrice"}
                  >
                    {language === "ar" ? "ر.س" : "SAR"} {product?.price}
                  </span>
                  {product?.discount > 0 && (
                    <span className="currentPrice">
                      {language === "ar" ? "ر.س" : "SAR"} {product?.discount}
                    </span>
                  )}
                </div>
                <p className="text text-center fw-bold">
                  {product?.details.split("#").map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </p>
                {product?.manual?.length > 4 && (
                  <div className="text-center">
                    <p>
                      {product?.manual.split("#").map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </p>
                  </div>
                )}
                <div>
                  <span
                    className="mx-3 fw-bold fs-2 py-2 px-3 rounded-2"
                    style={{ border: "1px solid black", cursor: "pointer" }}
                    onClick={() => setProductCount((prev) => prev + 1)}
                  >
                    +
                  </span>
                  <span className=" fw-bold fs-2">{productCount}</span>
                  <span
                    className="mx-3 fw-bold fs-2 py-2 px-3 rounded-2"
                    style={{ border: "1px solid black", cursor: "pointer" }}
                    onClick={() =>
                      setProductCount((prev) => (prev > 1 ? prev - 1 : 1))
                    }
                  >
                    -
                  </span>
                </div>
                <span
                  className="fav position-relative"
                  onClick={() => handlefav(product)}
                >
                  {addOrDelIsLoading && <Spin />}
                  {favItems?.find(
                    (el) => el.item_id === product?.id && !el.is_offer
                  ) ? (
                    <FontAwesomeIcon icon={faHeart} />
                  ) : (
                    <FontAwesomeIcon icon={regfaHeart} />
                  )}
                </span>
                <div className="linkstochart d-flex gap-2">
                  <button className="addtochart" onClick={handleAddToCart}>
                    {language == "ar" ? "اضف الي السلة " : "Add To Cart"}
                  </button>
                  <Link className="gotochart custom_button" to="/Chart">
                    {language == "ar" ? "الذهاب الي السلة " : "Go To Cart"}
                  </Link>
                </div>
                <Link className="backtopro" to="/Products">
                  {language == "ar"
                    ? "العودة الي المنتجات "
                    : "Back To Products"}
                </Link>
              </div>
            </Col>
          </Row>
        }
      </Container>
    </section>
  );
};

export default Product;
