import { useGetAllBrandsQuery } from "../../redux/Service/api";
import ServerError from "../../components/utils/ServerError";
import Loading from "../../components/utils/Loading";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";

import { FreeMode, Pagination, Navigation } from "swiper";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { language } from "../../language/Language";

export default function Category() {
  const route = useNavigate();
  const {
    data: allCategoriesData,
    isError: isCatError,
    isLoading: isCatLoading,
    error: catError,
  } = useGetAllBrandsQuery();
  const allCategories = allCategoriesData?.data ?? null;
  if (isCatError) {
    return <ServerError />;
  }
  return (
    <section
      className="Category section_padding mt-lg-5"
      data-aos={"fade-up"}
      style={{ position: "relative" }}
    >
      <Container>
        <h2 className="heading_cat">
          {language === "ar" ? "تسوق حسب الماركه" : "Shopping by brand"}
        </h2>
        <Swiper
          slidesPerView={8}
          breakpoints={{
            0: {
              slidesPerView: 2.5,
            },
            300: {
              slidesPerView: 3.5,
            },
            639: {
              slidesPerView: 3,
            },
            865: {
              slidesPerView: 4,
            },
            1000: {
              slidesPerView: 6,
            },
            1500: {
              slidesPerView: 7,
            },
            1700: {
              slidesPerView: 7,
            },
          }}
          spaceBetween={30}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[FreeMode, Pagination, Navigation]}
          className="mySwiper my-5"
        >
          {isCatLoading && <Loading />}
          {allCategories?.map((brand) => (
            <SwiperSlide key={brand.id} onClick={() => route(`/Products`)}>
              <img
                src={brand.photo}
                alt="Category"
                className="w-100 brand-img"
                style={{
                  height: "150px",
                  cursor: "pointer",
                  display: "flex",
                  objectFit: "contain",
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
}
