import "./whatsapp.css";
// import whatsapp from "../../image/whatsapp.png";
import { language } from "../../language/Language";
import { images } from "../../constants";

function Whatsapp() {
  // const language = localStorage.getItem("language") || "ar";

  return (
    // <!-- start whatsapp_massenger  -->
    <div className="whatsapp">
      <a
        href="https://api.whatsapp.com/send?phone=966555981921"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={images.Whatsapp} alt="" />
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=966555981921"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          <strong> {language == "ar" ? "تواصل معنا" : "contact us"}</strong>
        </p>
      </a>
    </div>
  );
}

export default Whatsapp;
