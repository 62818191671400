import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Footer.css";
import {
  faInstagram,
  faFacebookF,
  faPinterestP,
  faSquareFacebook,
  faSquareInstagram,
  faYoutube,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { language } from "../../language/Language";

// lahfa
import { Link } from "react-router-dom";

import { images } from "../../constants";

const Footer = () => {
  const route = useNavigate();

  return (
    <footer className=" pt-5 mt-3 box_shadow mt-5">
      <div className="footer container">
        <div className="row px-4 px-lg-0 mb-2">
          {/* col-2 */}
          <ul className="col-12 col-sm-12 col-lg-5 col-xl-2 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>{language == "ar" ? "شركه" : "a company"}</h6>
            </li>
            <li>
              <Link to="/About">
                {language == "ar" ? "من نحن" : "About Us"}
              </Link>
            </li>
            <li>
              <Link to="/Contact">
                {language == "ar" ? "تواصل معانا" : "Connect Us"}
              </Link>
            </li>
          </ul>
          {/* col-2 */}

          <ul className="col-12 col-sm-12 col-lg-5 col-xl-2 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>{language == "ar" ? "روابط مهمه" : "Important links"}</h6>
            </li>
            <li>
              <Link to="/Products">
                {language === "ar" ? "منتجاتنا" : "Products"}
              </Link>
            </li>
            {/* <li>
              <Link to="/Articles">
                {language === "ar" ? "مقالات" : "Articles"}
              </Link>
            </li> */}
          </ul>
          <ul className="col-12 col-sm-12 col-lg-5 col-xl-3 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>
                {language == "ar" ? "تريد مساعده؟" : "do you want help ?"}
              </h6>
            </li>
            <li>
              <Link to="mailto:INFO@modern-north-sa.com">
                <i className="bi bi-envelope"></i> INFO@modern-north-sa.com
              </Link>
            </li>
            <li>
              <Link
                to="https://api.whatsapp.com/send?phone=966555981921"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-phone"></i> +966555981921
              </Link>
            </li>
          </ul>
          {/* col-4 */}
          <ul className="col-12 col-lg-4 text-start-fremwork flex-column d-flex d-md-none">
            <li>
              <h6>
                {language == "ar" ? "نحن نقبل" : "We accept"}
                {/* {t("We_accept")} */}
              </h6>
            </li>
            <li className="imges d-flex flex-wrap gap-2 mb-3 mb-lg-0">
              {/* <img src={images.cash1} alt="" /> */}
              <img src={images.cash2} alt="" />
              <img src={images.cash3} alt="" />
              <img src={images.cash4} alt="" />
              <img src={images.cash5} alt="" />
              <img src={images.cash6} alt="" />
              <img src={images.cash7} alt="" />
              <img src={images.cash8} alt="" />
              {/* <img src={images.cash9} alt="" /> */}
              {/* <img src={images.cash10} alt="" /> */}
            </li>
          </ul>
          <ul className="col-12 col-sm-12 col-lg-5 col-xl-4 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>{language == "ar" ? "موقعنا" : "Our Location"}</h6>
            </li>
            <li>
              <Link
                to="http://bit.ly/LahfaShubraStore"
                target="_blank"
                className="gap-2 d-flex flex-nowrap"
              >
                <i className="bi bi-geo-alt"></i>
                {language == "ar"
                  ? "حي، شارع الاربعين، الصفا، جدة 23454، المملكة العربية السعودية"
                  : "District, Al Arbaeen Street, Al Safa, Jeddah 23454, Kingdom of Saudi Arabia"}
              </Link>
              <iframe
                className="w-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7358388.597167618!2d39.72739833514931!3d25.77177962257064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d1003097c999%3A0xeb8100a5597b8d14!2z2YXYpNiz2LPYqSDYp9mE2LTZhdin2YQg2KfZhNit2K_Zitir2Kkg2YTZhNij2KzZh9iy2Kkg2KfZhNmF2YbYstmE2YrZhw!5e0!3m2!1sar!2seg!4v1724524928211!5m2!1sar!2seg"
                width="300"
                height="200"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </li>
          </ul>
        </div>
        <div className="row px-4 px-lg-0 justify-content-around d-flex">
          <ul className="col-12 col-lg-4 text-start-fremwork flex-column d-none d-md-flex mb-3">
            <li>
              <h6>{language == "ar" ? "نحن نقبل" : "We accept"}</h6>
            </li>
            <li className="imges d-flex flex-wrap gap-2">
              {/* <img src={images.cash1} alt="" /> */}
              <img src={images.cash2} alt="" />
              <img src={images.cash3} alt="" />
              <img src={images.cash4} alt="" />
              <img src={images.cash5} alt="" />
              <img src={images.cash6} alt="" />
              <img src={images.cash7} alt="" />
              <img src={images.cash8} alt="" />
              {/* <img src={images.cash9} alt="" /> */}
              {/* <img src={images.cash10} alt="" /> */}
            </li>
          </ul>

          <div className="col-12 col-lg-4 text-start-fremwork ">
            {/* <h6 className="heading_meada">
              {language == "ar" ? "تابعنا" : "Follow us"}
            </h6>
            <div className="meada">
              <a
                href="https://www.facebook.com/OrganicaGroupBeauty?mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faSquareFacebook} />
              </a>
              <a
                href="https://www.facebook.com/OrganicaGroup?mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faSquareFacebook} />
              </a>
              <a
                href="https://www.instagram.com/organica.group?igsh=MTdrMHFyMnNvNXdudw=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faSquareInstagram} />
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <p className="m-0 p-3 copay">
        {language == "ar"
          ? "Ⓒ جميع الحقوق محفوظة لشركة"
          : "Ⓒ All rights reserved to"}{" "}
        <a
          href="https://prosofteg.com"
          target="_blank"
          rel="noopener noreferrer"
          className="ms-1 me-1 mt-0 mb-0"
        >
          {" "}
          ProsoftEg
        </a>
      </p>
    </footer>
  );
};

export default Footer;
