import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal, Space, Upload, message, Button, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  useAddBrandMutation,
  useAddCategoryMutation,
} from "../../redux/Service/api";

const AddCategory = ({ setOpenAddModal, openAddModal }) => {
  const [modalKey, setModalKey] = useState(0);

  const [nameEN, setNameEN] = useState("");
  const [nameAR, setNameAR] = useState("");

  const [file, setFile] = useState(null);

  const [addCategory, { isLoading, isError, error }] = useAddBrandMutation();

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddOffer = async () => {
    if (!nameAR) {
      message.error("الرجاء إضافة الاسم العربي");
      return;
    }
    if (!nameEN) {
      message.error("الرجاء إضافة الاسم الانجليزي");
      return;
    }
    if (!file) {
      message.error("يرجى تحميل ملف");
      return;
    }
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("name", nameAR);
    formData.append("name_en", nameEN);

    try {
      const res = await addCategory(formData);
      setFile(null);
      setNameAR("");
      setNameEN("");
      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("تمت إضافة العلامة التجارية بنجاح");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Brand:", error);
      message.error(
        "حدث خطأ أثناء إضافة العلامة التجارية. يرجى المحاولة مرة أخرى."
      );
    }
  };

  return (
    <Modal
      key={modalKey}
      title="اضافه علامه تجاريه"
      centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={500}
      footer
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="الاسم بالعربي"
                value={nameAR}
                onChange={(e) => setNameAR(e.target.value)}
              />
              <Input
                placeholder="الاسم بالانجليزي"
                value={nameEN}
                onChange={(e) => setNameEN(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column col-4">
              <Upload
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleFileChange(fileList);
                }}
                listType="picture"
                maxCount={1}
              >
                <Button
                  loading={false}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  تحميل الصورة (الحد الأقصى: 1)
                </Button>
              </Upload>
            </Col>
          </Row>
          <Row className="g-2">
            <Button type="primary" onClick={handleAddOffer} loading={isLoading}>
              إضافة العلامة التجارية
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default AddCategory;
