// www.burdastyle.com
import logo from "../assets/logo.jpg";
import services1 from "../assets/services1.svg";
import services2 from "../assets/services2.svg";
import services3 from "../assets/services3.svg";
import services4 from "../assets/services4.svg";
// user
import user from "../assets/user.jpg";
// footer mok
import cash1 from "../assets/cash-1.jpg";
import cash2 from "../assets/cash-2.jpg";
import cash3 from "../assets/cash-3.png";
import cash4 from "../assets/cash-4.svg";
import cash5 from "../assets/cash-5.png";
import cash6 from "../assets/cash-6.png";
import cash7 from "../assets/cash-8.png";
import cash8 from "../assets/cash-9.svg";
import cash9 from "../assets/cash-11.svg";
import cash10 from "../assets/cash-12.png";

// Whatsapp
import Whatsapp from "../assets/whatsapp.png";

export default {
  user,
  logo,
  services1,
  services2,
  services3,
  services4,
  cash1,
  cash2,
  cash3,
  cash4,
  cash5,
  cash6,
  cash7,
  cash8,
  cash9,
  cash10,
  Whatsapp,
};
