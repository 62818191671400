import {
  Button,
  Dropdown,
  Flex,
  Input,
  message,
  Modal,
  Space,
  Table,
} from "antd";
import { useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { ExclamationCircleFilled } from "@ant-design/icons";

import {
  useAdminGetAllUserQuery,
  useDeleteUserMutation,
  useSwapUserRoleMutation,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import ServerError from "../../components/utils/ServerError";
import { exportToExcel } from "../../components/utils/ExportToExcel";

const { confirm } = Modal;

const UsersControl = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [
    deleteUser,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeleteUserMutation();
  const [
    swapUserRole,
    { isLoading: swapIsLoading, isError: swapIsError, error: swapError },
  ] = useSwapUserRoleMutation();

  const {
    data: allUsersData,
    isError: isUsersDataError,
    isLoading: isUsersDataLoading,
    error: usersDatacatError,
  } = useAdminGetAllUserQuery();
  const allUsers = allUsersData?.data ?? null;

  const data = allUsers?.map((user) => {
    return {
      key: user.id,
      name: user.name,
      email: user.email,
      phone: user.phone ?? "",
      status: user.role,
      address: user.addresses?.length > 0 ? user.addresses[0].full_address : "",
      created_at: user.created_at,
    };
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            بحث
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            إعادة ضبط
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            فلتر
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            اغلق
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "الاسم",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      width: 150,
    },
    {
      title: "ايميل",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      width: 130,
    },
    {
      title: "الهاتف",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone"),
      width: 100,
    },
    {
      title: "العنوان",
      dataIndex: "address",
      key: "address",
      ...getColumnSearchProps("address"),
      width: 150,
    },
    {
      title: "نوع المتسخدام",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status"),
      width: 100,
    },
    {
      title: "تم إنشاؤها في",
      dataIndex: "created_at",
      key: "created_at",
      ...getColumnSearchProps("created_at"),
      width: 100,
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (userDataMapper) => {
        return (
          <div className="d-flex gap-2">
            <Button
              type="primary"
              danger
              ghost
              onClick={() => handleDeleteUserClick(userDataMapper.key)}
            >
              خذف
            </Button>
            {userDataMapper.status === "admin" ? (
              <Button onClick={() => handleSwapRoleClick(userDataMapper.key)}>
                جعل المستخدم
              </Button>
            ) : (
              <Button onClick={() => handleSwapRoleClick(userDataMapper.key)}>
                جعل المشرف
              </Button>
            )}
          </div>
        );
      },
      width: 200,
    },
  ];

  const handleDeleteUserClick = async (id) => {
    confirm({
      title: "هل تريد حذف هذا المستخدم?",
      icon: <ExclamationCircleFilled />,
      content: "عند النقر على زر موافق، ستتم إزالة هذا المستخدم",
      async onOk() {
        try {
          const res = await deleteUser(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("تم حذف المستخدم بنجاح");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };
  const handleSwapRoleClick = async (id) => {
    confirm({
      title: "هل تريد تبديل دور هذا المستخدم?",
      icon: <ExclamationCircleFilled />,
      content: "عند النقر على زر موافق، سيتم تبديل دور هذا المستخدم",
      async onOk() {
        try {
          const res = await swapUserRole(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("تم تبديل المستخدم بنجاح");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };
  return (
    <>
      <article className="users position-relative">
        {isUsersDataError && <ServerError />}
        <article className="container users_container">
          <h2 className="main_heading">المستخدمين</h2>
          <Button
            type="primary"
            className="mb-2"
            onClick={() => exportToExcel(data, "users")}
          >
            تصدير إلى إكسل
          </Button>
          <Table
            columns={columns}
            dataSource={data}
            loading={isUsersDataLoading}
            bordered
            rowClassName={"rowName"}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: [9, 10, 20, 30, 50],
              showTotal: (total) => `العدد الكلي: ${total}`,
            }}
            scroll={{ y: 400, x: 500 }}
          />
        </article>
      </article>
    </>
  );
};
export default UsersControl;
