import React from "react";
import { Container } from "react-bootstrap";
import "./style.css";

function BannerImage({ img }) {
  return (
    <Container className="text-center">
      <img
        className="BannerImage"
        style={{ width: "100%", objectFit: "contain" }}
        src={img}
        alt="BannerImage"
      ></img>
    </Container>
  );
}

export default BannerImage;
